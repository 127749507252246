import { VERSION } from '../../constants/constants';
import http from '../http';

import { auth } from './auth/auth';

const USER = VERSION + '/user';
const INFO = USER + '/info';

export const user = {
  auth,
  infoBySelf: async () => {
    const res = await http.get(INFO);

    return res;
  },
};
