import { Form, List } from 'antd';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { GoShieldLock, GoVerified } from 'react-icons/go';

import { rootStore } from '../../stores/rootStore';
import BlockSpint from '../BlockSpint/BlockSpint';

import Actions from './Actions/Actions';
import { useLoadClient } from './hooks/useLoadClient';
import LogoutButton from './LogoutButton/LogoutButton';

import styles from './Approve.module.scss';

interface ApproveProps {
  className?: string;
}

const data = ['Личные данные пользователя', 'Запросы авторизации'];

const Approve: FC<ApproveProps> = ({ className }) => {
  const { user } = rootStore.userDataStore;
  const { params } = rootStore.oauthStore;

  const { client, isLoading } = useLoadClient(params);

  if (!params) return <div>Error params = {params}</div>;
  if (isLoading) return <BlockSpint />;

  return (
    <>
      <LogoutButton />
      <Form className={styles.form}>
        <h1 className={styles.head}>Здравствуйте {user?.name}</h1>
        <h2 className={styles.title}>
          Приложение <strong>{client?.name}</strong> запрашивает доступ к вашей
          учётной записи ЕПУТС <strong>{user?.login}</strong>
        </h2>
        <div className={styles.dsc}>
          <GoShieldLock className={styles.icon} size={40} />
          <p className={styles.dscText}>
            <strong>{client?.name}</strong> хочет получить возможность доступа к
            функционалу перечисленному ниже:
          </p>
        </div>

        <List
          className={styles.list}
          dataSource={data}
          size="small"
          renderItem={(item) => (
            <List.Item className={styles.item}>
              <>
                <GoVerified />
                <span>{item}</span>
              </>
            </List.Item>
          )}
        />
        <Actions />
      </Form>
    </>
  );
};

export default observer(Approve);
