import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { ReactComponent as OsutLogoNonText } from '../../assets/icons/ic_osut_none_text.svg';

import styles from './Logo.module.scss';
interface ILogoProps {
  isSmall?: boolean;
}

const Logo: React.FC<ILogoProps> = (props: ILogoProps) => {
  const { isSmall } = props;

  return (
    <div className={styles.container}>
      <OsutLogoNonText
        className={classNames({
          [styles.logoOsut]: !isSmall,
          [styles.logoSmall]: isSmall,
        })}
      />
    </div>
  );
};

export default observer(Logo);
