import { VERSION } from '../../../constants/constants';
import http from '../../http';

const AUTH = VERSION + '/user/auth';

export const auth = {
  authorization: async (login: string, password: string) => {
    const request = await http.post(`${AUTH}/authorization`, {
      login,
      password,
    });

    return request;
  },
  refreshToken: async (refreshToken: string) => {
    const request = await http.get(`${AUTH}/refresh-token`, {
      headers: {
        'Refresh-Token': `Bearer ${refreshToken}`,
      },
    });

    return request;
  },
};
