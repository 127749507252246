import { Spin } from 'antd';
import { FC } from 'react';

import styles from './BlockSpint.module.scss';

interface BlockSpintProps {
  className?: string;
}

const BlockSpint: FC<BlockSpintProps> = ({ className }) => {
  return (
    <div className={styles.container}>
      <Spin size="large" />
    </div>
  );
};

export default BlockSpint;
