import { useEffect, useState } from 'react';

import api from '../../../api';
import { Client, Params } from '../../../stores/oauthStore/oauthStore';
import { rootStore } from '../../../stores/rootStore';

export const useLoadClient = (params: N<Params>) => {
  const { setKeyValue } = rootStore.oauthStore;
  const [client, setClient] = useState<N<Client>>(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchClient = async () => {
    try {
      setIsLoading(true);
      if (!params) return console.error('fetchClient', 'params is', params);
      const res = await api.oAuth.clients.getItemByUid(params.clientId);
      
      setClient(res);
    } catch (e: any) {
      setKeyValue('error', 'Ошибка запроса клиента');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchClient();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  return {
    client,
    isLoading,
  };
};
