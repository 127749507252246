/* eslint-disable react/jsx-no-target-blank */

import { Result } from 'antd';
import { observer } from 'mobx-react-lite';

import { useApproveParams } from '../../hooks/useApproveParams';
import useAuth from '../../hooks/useAuth';
import { useNoResize } from '../../hooks/useNoResize';
import { rootStore } from '../../stores/rootStore';
import Approve from '../Approve/Approve';
import BlockSpint from '../BlockSpint/BlockSpint';
import Login from '../Login/Login';
import LogoDsc from '../LogoDsc/LogoDsc';

import styles from './App.module.scss';

function App() {
  const { isAuth, isAppReady } = rootStore.userDataStore;
  const { error } = rootStore.oauthStore;

  useApproveParams();

  // const handler = () => {
  //   const url = "/?onWindow=true"

  //   const windowFeatures = "directories=0,location=0,menubar=0,status=0,toolbar=0, left=1000,top=300,width=500,height=600";

  //   //'toolbar=no, popup=yes, location=no, status=no, resizable=no, scrollBars=yes, Width=600, Height=400, screenX=500, top=300'

  //   window.open(url, 'НазванияОкна', windowFeatures)
  //   window.close();
  // }

  useAuth();
  useNoResize([500, 600]);

  const getComponent = () => {
    if (!isAppReady) return <BlockSpint />;

    if (error) return <Result status="error" title={error} />;

    if (isAuth) return <Approve />;

    return <Login />;
  };

  return (
    <main className={styles.main}>
      <div className={styles.formContainer}>
        <LogoDsc />
        <div className={styles.content}>{getComponent()}</div>
      </div>
    </main>
  );
}

export default observer(App);
