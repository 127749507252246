interface INoticeItem {
  message: string;
  description: string;
  duration?: number;
}

export const NOTICE: Record<string, INoticeItem> = {
  LOGIN_SUCCESSFUL: {
    message: 'Вход выполнен',
    description: 'Вы успешно вошли в аккаунт',
  },
  USER_ACCESS_ERROR: {
    message: 'Отсутствует доступ',
    description:
      'Для данного пользователя отсутствуют разрешения на доступ к элементам интерфейса. Пожалуйста, обратитесь к администратору.',
  },
  SYSTEM_GROUPS_ERROR: {
    message: 'Ошибка сервера',
    description: 'Группы доступа системы недоступны.',
  },
};
