/* eslint-disable jsx-a11y/no-autofocus */
import { Form, Button, Checkbox, notification } from 'antd';
import { FC, useState } from 'react';

import { ReactComponent as Lock } from '../../../assets/icons/ic_lock_2.svg';
import { ReactComponent as Profile } from '../../../assets/icons/ic_profile.svg';
import { NOTICE } from '../../../constants/notificationConstants';
import useAuth from '../../../hooks/useAuth';
import useLoginForm from '../../../hooks/useLoginForm';

import styles from './LoginForm.module.scss';

const MAX_LENGTH = 50;

const LoginForm: FC = () => {
  const [{ userName, password, isRemember }, handleForm]: any[] =
    useLoginForm();
  const { loginHandler, checkAuthHandler } = useAuth();
  const [isNotValid, setIsNotValid] = useState(false);
  const [authPending, setAuthPending] = useState(false);

  const onChange = () => setIsNotValid(false);

  const handleLogin = async () => {
    if (userName.length > MAX_LENGTH || password.length > MAX_LENGTH)
      return setIsNotValid(true);

    setAuthPending(true);

    const authSuccessfull = await loginHandler(userName, password, isRemember);

    if (authSuccessfull === undefined) {
      return setAuthPending(false);
    }

    if (!authSuccessfull) {
      setAuthPending(false);

      return setIsNotValid(true);
    }

    notification.success(NOTICE.LOGIN_SUCCESSFUL);

    await checkAuthHandler();
    setAuthPending(false);
    setIsNotValid(false);
  };

  return (
    <section className={styles.container}>
      <Form layout="vertical" onChange={handleForm} className={styles.wrapper}>
        <Form.Item
          className={styles.inputContainer}
          label="Логин"
          validateStatus="error"
          help={isNotValid && 'Неверное имя пользователя или пароль'}
          // @ts-ignore
          onChange={onChange}
        >
          <div className={styles.inputWrapper}>
            <Profile className={styles.icon} />
            <input
              id="login"
              className={styles.input}
              disabled={authPending}
              autoFocus={true}
            />
          </div>
        </Form.Item>
        <Form.Item
          className={styles.inputContainer}
          label="Пароль"
          validateStatus="error"
          help={isNotValid && 'Неверное имя пользователя или пароль'}
          // @ts-ignore
          onChange={onChange}
        >
          <div className={styles.inputWrapper}>
            <Lock className={styles.icon} />
            <input
              id="password"
              type="password"
              className={styles.input}
              disabled={authPending}
            />
          </div>
        </Form.Item>
        <Form.Item>
          <div className={styles.radioRow}>
            <Checkbox checked={isRemember}>Запомнить меня</Checkbox>
          </div>
        </Form.Item>
        <Form.Item>
          <Button
            className={styles.btn}
            disabled={!password || !userName || isNotValid || authPending}
            onClick={handleLogin}
          >
            Войти в систему
          </Button>
        </Form.Item>
      </Form>
    </section>
  );
};

export default LoginForm;
