import { makeAutoObservable } from 'mobx';

import { LOCAL_STORAGE_REFRESH_TOKEN_KEY } from '../../constants/constants';
import { rootStore as RootStore } from '../rootStore';
import { StoreUtils } from '../storeUtils/storeUtils';

export interface ITokens {
  access: string;
  refresh: string;
}

export enum Role {
  admin = 'admin',
  dispatcher = 'dispatcher',
  operator = 'operator',
  engineer = 'engineer',
  admin_root = 'admin_root',
}

export interface IGroup {
  id: number;
  name: string;
  code: Role;
  dsc: string;
}

export interface IUserData {
  id: number;
  groups: number[];
  jprofile: Record<string, any> | null;
  login: string;
  name: string;
  dsc: string;
  email: string;
  regDate: string;
  jsonb_info: Record<string, string[]>;
  regions_id: number[];
}

export class UserDataStore {
  rootStore;
  isAppReady = false;
  isAuth = false;
  accessToken: N<string> = null;
  tempRefreshToken: N<string> = null;
  user: N<IUserData> = null;
  groups = [];
  webSystemId = 0;
  serverSystemId = 0;
  isNeedPageReload = false;
  setKeyValue;
  clearStore;
  setKeysValues;

  constructor(rootStore: typeof RootStore) {
    makeAutoObservable(this, {
      rootStore: false,
    });
    this.rootStore = rootStore;

    const utils = new StoreUtils(this);

    this.setKeyValue = utils.setKeyValue;
    this.setKeysValues = utils.setKeysValues;
    this.clearStore = utils.clearStore;
  }
  setTokens = (tokens: ITokens, isRemember?: boolean) => {
    const { access, refresh } = tokens;

    if (isRemember) {
      localStorage.setItem(LOCAL_STORAGE_REFRESH_TOKEN_KEY, refresh);
    } else {
      localStorage.removeItem(LOCAL_STORAGE_REFRESH_TOKEN_KEY);
      this.tempRefreshToken = refresh;
    }

    this.accessToken = access;
  };

  get isRemember() {
    return Boolean(localStorage.getItem(LOCAL_STORAGE_REFRESH_TOKEN_KEY));
  }

  get refreshToken() {
    const refresh =
      localStorage.getItem(LOCAL_STORAGE_REFRESH_TOKEN_KEY) ??
      this.tempRefreshToken;

    return refresh;
  }

  setUserData = (userData: IUserData) => {
    this.user = userData;
    this.isAuth = true;
  };

  resetUserData = () => {
    localStorage.removeItem(LOCAL_STORAGE_REFRESH_TOKEN_KEY);

    this.isAuth = false;
    this.user = null;
    this.accessToken = null;
    this.tempRefreshToken = null;
    this.isNeedPageReload = false;
  };
}
