import { notification } from 'antd';
import axios from 'axios';
// eslint-disable-next-line no-duplicate-imports
import type { AxiosRequestConfig } from 'axios';

import { CONFIG } from '../constants/configConstants';
import onRejectedError from '../helpers/onRejectedError';
import { rootStore } from '../stores/rootStore';

import { refreshTokensRequest } from './helpers/refreshTokensRequest';

const HEADERS = {
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*',
};

const requestSettings: AxiosRequestConfig = {
  baseURL: CONFIG.EPUTS_URL,
  headers: HEADERS,
};

const http = axios.create(requestSettings);

http.interceptors.response.use((config) => {
  config.status !== 502 &&
    config.status !== 0 &&
    notification.destroy('error-notification');

  return config;
}, onRejectedError);

http.interceptors.request.use((config: any) => {
  const { userDataStore } = rootStore;
  const accessToken: any = userDataStore?.accessToken;

  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }

  return config;
});

http.interceptors.response.use(
  (config) => config,
  async (error) => {
    const originalRequest = error.config;

    if (
      error.response.status === 401 &&
      originalRequest &&
      !error.config._isRetry
    ) {
      originalRequest._isRetry = true;

      try {
        await refreshTokensRequest();

        return http.request(originalRequest);
      } catch (e: any) {
        throw error;
      }
    }

    throw error;
  }
);

export default http;
