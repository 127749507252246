import { useEffect } from 'react';

import { rootStore } from '../stores/rootStore';

export const useApproveParams = () => {
  const { setKeyValue } = rootStore.oauthStore;

  useEffect(() => {
    const url = new URL(window.location.href);

    const clientId = url.searchParams.get('client_id');

    if (!clientId) return setKeyValue('error', 'Отсутствует параметр client_id');

    const reqId = url.searchParams.get('reqId');

    if (!reqId) return setKeyValue('error', 'Отсутствует параметр reqId');

    setKeyValue('params', {
      clientId,
      reqId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
