import { Button } from 'antd';
import { FC } from 'react';
import { IoIosLogOut } from "react-icons/io";

import { rootStore } from '../../../stores/rootStore';

import styles from './LogoutButton.module.scss'



const LogoutButton: FC = () => {
	const { resetUserData } = rootStore.userDataStore;

	return (
		<Button
			size='small'
			type='default'
			className={styles.logout}
			danger
			onClick={resetUserData}
		>
			<p className={styles.text}>
				Сменить
				пользователя
			</p>
			<IoIosLogOut className={styles.icon} size={40}/>
		</Button>
	)
}

export default LogoutButton