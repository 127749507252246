import { VERSION } from '../../../constants/constants';
import http from '../../http';

const SERVER = VERSION + '/server';
const GROUPS = SERVER + '/groups';

export const info = {
  fetchGroups: async () => {
    const res = await http.get(GROUPS);

    return res.data;
  },
};
