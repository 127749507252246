import { notification } from 'antd';
import { useCallback, useEffect } from 'react';

import api from '../api';
import { refreshTokensRequest } from '../api/helpers/refreshTokensRequest';
import { NOTICE } from '../constants/notificationConstants';
import { rootStore } from '../stores/rootStore';
import { IGroup } from '../stores/userDataStore/userDataStore';
import { UserRoleScales } from '../types/enums/userData';

const useAuth = () => {
  const { userDataStore } = rootStore;

  useEffect(() => {
    checkAuthHandler();
  }, []); // eslint-disable-line

  const checkAuthHandler = useCallback(async () => {
    try {
      userDataStore.setKeyValue('isAppReady', false);

      const accessToken = userDataStore.accessToken;
      const isRemember = userDataStore.isRemember;

      if (!accessToken && !isRemember) return;

      if (!accessToken) {
        await refreshTokensRequest();
      }

      const response = await api.user.infoBySelf();
      const { user } = response.data;
      const accessEls = user?.jsonb_info?.accesswebelements;

      if (!accessEls || !accessEls.length) {
        return notification.error(NOTICE.USER_ACCESS_ERROR);
      }

      const groupsData = await api.serverObjects.info.fetchGroups();

      if (!groupsData || !groupsData.groups.length) {
        return notification.error(NOTICE.SYSTEM_GROUPS_ERROR);
      }

      const webGroups = groupsData.groups;
      let roleValue;
      let scaleValue = 0;

      user?.groups.forEach((userGroup: number) => {
        webGroups.forEach((webGroup: IGroup) => {
          if (userGroup === Number(webGroup.id)) {
            if (scaleValue < UserRoleScales[webGroup.code])
              scaleValue = UserRoleScales[webGroup.code];

            roleValue = UserRoleScales[scaleValue];
          }
        });
      });

      if (!roleValue) {
        notification.error(NOTICE.USER_ACCESS_ERROR);
        throw new Error(
          'User groups do not match the available application groups'
        );
      }

      userDataStore.setKeysValues({
        webSystemId: groupsData.systemId,
        serverSystemId: groupsData.serverSystemId,
        groups: groupsData.groups,
      });

      userDataStore.setUserData(user);
    } catch (e: any) {
      if (e?.response?.status === 403) {
        notification.error({
          message: 'Доступ заблокирован',
          description: e?.response?.data?.additional?.reason,
        });
      }

      userDataStore.resetUserData();
    } finally {
      userDataStore.setKeyValue('isAppReady', true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loginHandler = useCallback(
    async (login: string, password: string, isRemember: boolean) => {
      try {
        const request = await api.user.auth.authorization(login, password);
        const response = request.data;

        userDataStore.setTokens(response.tokens, isRemember);

        return true;
      } catch (e: any) {
        userDataStore.resetUserData();

        if (e?.response?.status === 400) {
          return false;
        }

        if (e?.response?.status === 403) {
          notification.error({
            message: 'Доступ заблокирован',
            description: e?.response?.data?.additional?.reason,
          });
        }

        return undefined;
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    []
  );

  return { loginHandler, checkAuthHandler };
};

export default useAuth;
