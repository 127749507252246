import { VERSION } from '../../constants/constants';
import http from '../http';

import { clients } from './clients/clients';

const OAUTH = VERSION + '/oauth';

export const oAuth = {
  clients,
  approve: async (reqId: string): Promise<string> => {
    const res = await http.get(OAUTH + '/approve', {
      maxRedirects: 1,
      params: {
        reqId,
      },
    });

    return res.data.redirectUri;
  },
  deny: async (reqId: string) => {
    const res = await http.get(OAUTH + '/deny', {
      params: {
        reqId,
      },
    });

    return res.data.redirectUri;
  },
};
