const { REACT_APP_OFFICE, REACT_APP_API_INSTANCE_NAME } = process.env;

const INSTANCE = REACT_APP_API_INSTANCE_NAME ?? 'njs';
const API = `/${INSTANCE}/api`;

const location =
  typeof window !== 'undefined'
    ? window.location
    : {
        protocol: 'http',
        origin: '{хост сервера отдачи бандла}',
        host: '{хост сервера отдачи бандла}',
      };
const originUrl = location?.origin;

interface IConfig {
  VERSION: number;
  EPUTS_URL: string;
}

const BASIC: IConfig = {
  VERSION: 1,
  EPUTS_URL: originUrl + API,
};

const DEV: IConfig = {
  ...BASIC,
  EPUTS_URL: `https://dev.eputs.org${API}`,
};

const DEV_OFFICE: IConfig = {
  ...BASIC,
  EPUTS_URL: `https://${REACT_APP_OFFICE}`,
};

const LOCAL: IConfig = {
  ...BASIC,
  EPUTS_URL: 'https://dbrz8ph3-5000.euw.devtunnels.ms/api',
};

const BUILD: IConfig = {
  ...BASIC,
};

const BUILD_OFFICE: IConfig = {
  ...BASIC,
};

const PROD: IConfig = {
  ...BASIC,
};

const KHABAROVSK: IConfig = {
  ...PROD,
};

const CONFIG_NAMES = {
  DEV,
  DEV_OFFICE,
  BUILD_OFFICE,
  LOCAL,
  BUILD,
  KHABAROVSK,
} as const;

type TConfigNames = keyof typeof CONFIG_NAMES;

const configName = process.env.REACT_APP_CONFIG_NAME as TConfigNames;

if (!CONFIG_NAMES[configName]) {
  const cfgError = {
    message: `Ошибка обработки конфига, название конфига из .env '${configName}' не соответствует допустимым значениям`,
    REACT_APP_CONFIG_NAME: configName,
    existConfigsNames: Object.keys(CONFIG_NAMES),
  };

  console.error('Ошибка обработки конфига', JSON.stringify(cfgError, null, 2));
}

export const CONFIG: IConfig = CONFIG_NAMES[configName] ?? CONFIG_NAMES.DEV;
