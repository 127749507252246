import { Button } from 'antd';
import { FC, useEffect, useState } from 'react';

import api from '../../../api';
import { rootStore } from '../../../stores/rootStore';
import BlockSpint from '../../BlockSpint/BlockSpint';

import { useRememberApprove } from './hooks/useRememberApprove';

import styles from './Actions.module.scss';

const Actions: FC = () => {
  const { params, setKeyValue } = rootStore.oauthStore;
  const [isLoading, setIsLoading] = useState(false);

  const [isRememberApprove, setRemember] = useRememberApprove(params?.clientId)

  const request =
    (isDeny = false) =>
    async () => {
      console.log("request",);
      try {
        setIsLoading(true);

        if (!params) throw new Error('no params');

        const redirectUri = isDeny
          ? await api.oAuth.deny(params?.reqId)
          : await api.oAuth.approve(params?.reqId);

        window.location.href = redirectUri;
      } catch (e: any) {
        setKeyValue('error', `approve: ${e.message}`);
      } finally {
        setIsLoading(false);
      }
    };

  const approveReq = request();

  const approve = () => {
    setRemember();
    approveReq();
  }
  const deny = request(true);

  useEffect(() => {
    if(!isRememberApprove) return;

    console.log("useEffect","isRememberApprove");

    approve()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRememberApprove])

  if (isLoading) return <BlockSpint />;

  return (
    <div className={styles.buttons}>
      <Button size="large" type="primary" onClick={approve} loading={isLoading}>
        Подтвердить
      </Button>
      <Button size="large" type="primary" danger onClick={deny} loading={isLoading}>
        Отклонить
      </Button>
    </div>
  );
};

export default Actions;
