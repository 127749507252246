import { VERSION } from '../../../constants/constants';
import http from '../../http';

const CLIENTS = VERSION + '/oauth/clients';

export const clients = {
  getItemByUid: async (uid: string) => {
    const res = await http.get(CLIENTS + `/${uid}`);

    return res.data;
  },
};
