import { useEffect } from 'react';

type InitialSize = [number, number];

export const useNoResize = (initialSize: InitialSize) => {
  const resizeFunc = () => {
    window.resizeTo(initialSize[0] + 16, initialSize[1] + 75);
  };

  useEffect(() => {
    window.addEventListener('resize', resizeFunc);

    return () => {
      window.addEventListener('resize', resizeFunc);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
