import { makeAutoObservable } from 'mobx';

import { rootStore as RootStore } from '../rootStore';
import { StoreUtils } from '../storeUtils/storeUtils';

export interface Params {
  reqId: string;
  clientId: string;
}

export interface Client {
  name: string;
  dsc: string;
  uid: string;
  jsonb_info: OWKS<any>;
  redirects_uri: string[];
}

export class OauthStore {
  rootStore;
  params: N<Params> = null;
  client: N<Client> = null;
  error: N<string> = null;

  setKeyValue;
  clearStore;
  setKeysValues;

  constructor(rootStore: typeof RootStore) {
    makeAutoObservable(this, {
      rootStore: false,
    });
    this.rootStore = rootStore;

    const utils = new StoreUtils(this);

    this.setKeyValue = utils.setKeyValue;
    this.setKeysValues = utils.setKeysValues;
    this.clearStore = utils.clearStore;
  }
}
