import { notification } from 'antd';

import api from '..';
import { rootStore } from '../../stores/rootStore';

export const refreshTokensRequest = async () => {
  try {
    const { userDataStore } = rootStore;
    const refreshToken = userDataStore.refreshToken;

    if (!refreshToken) {
      throw new Error('Отсутствует refreshToken');
    }

    const request = await api.user.auth.refreshToken(refreshToken);
    const response = request.data;

    userDataStore.setTokens(response.tokens, userDataStore.isRemember);
  } catch (e: any) {
    if (e?.response?.status === 403) {
      notification.error({
        message: 'Доступ заблокирован',
        description: e?.response?.data?.additional?.error,
      });
    } else {
      notification.warning({
        message: 'Пожалуйста войдите в аккаунт',
        description: 'Срок действия авторизации истёк',
      });
    }

    rootStore.userDataStore.resetUserData();
    throw e;
  }
};
