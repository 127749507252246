import { FC } from 'react';

import Logo from '../Logo/Logo';

import styles from './LogoDsc.module.scss';

interface LogoDscProps {
  className?: string;
}

const LogoDsc: FC<LogoDscProps> = ({ className }) => {
  return (
    <div className={styles.box}>
      <div className={styles.logoBox}>
        <Logo />
        <div className={styles.wrapper}>
          <p className={styles.abbreviation}>
            ЕПУТС
          </p>
          <p className={styles.serviceName}>
            АВТОРИЗАЦИЯ
          </p>
        </div>
      </div>
    </div>
  );
};

export default LogoDsc;
