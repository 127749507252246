import { useEffect, useState } from "react"

const LOCAL_STORAGE_APPROVE_KEY = 'approveKey@';
const LOCAL_STORAGE_APPROVE_VALUE = "true"

export const useRememberApprove = (clientId: U<string>): [boolean, () => void] => {
	const [isRememberApprove, setIsRememberApprove] = useState(false);

	const setRemember = () => {
		if(!clientId) return;

		const clientApproveKey = LOCAL_STORAGE_APPROVE_KEY + clientId;

		localStorage.setItem(clientApproveKey, LOCAL_STORAGE_APPROVE_VALUE)
	}

	const check = () => {
		if(!clientId) return;

		const clientApproveKey = LOCAL_STORAGE_APPROVE_KEY + clientId;

		const exist = localStorage.getItem(clientApproveKey);

		if(exist !== LOCAL_STORAGE_APPROVE_VALUE) return setIsRememberApprove(false)

		console.log("exist",exist);

		setIsRememberApprove(true)
	}

	useEffect(() => {
		check()
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return [isRememberApprove, setRemember]
}