// eslint-disable-next-line import/no-unresolved
import { OauthStore } from './oauthStore/oauthStore';
import { UserDataStore } from './userDataStore/userDataStore';

class RootStore {
  userDataStore;
  oauthStore;

  constructor() {
    this.userDataStore = new UserDataStore(this);
    this.oauthStore = new OauthStore(this);
  }
}

export const rootStore = new RootStore();
